<div  [ngSwitch]="countryCode">
  <div *ngSwitchCase="'IQ'" >
    <h1 style="color:#283592; font-size: 28px ; direction: {{alignText}}" class="ml-lg-3  ">فاتورة</h1>
    <div class="row pl-lg-4" style="padding-left: 20px; padding-right: 20px ;  direction: {{alignText}}">
      <div class="col-4 text-left">
        <!--        <h3>Invoice </h3>-->
        <address style="font-size: 14px ;">
          الرقم : <b style="color: #666666; margin-right: 5px">{{invoiceId}}</b><br>
          التاریخ : <b style="color: #666666; margin-right: 5px">{{data.created_at | date}}</b><br>
          طريقة الدفع : <b style="color: #666666; margin-right: 5px">{{data?.payment_method_language?.name }}</b><br>
          الحالة : <b [style.color]="!orderStatus && data?.current_state == 'canceled' ? 'red' : '#002c8a'" style=" margin-right: 5px;"
                      class="text-capitalize">{{orderStatus ? orderStatus :data?.current_state }}</b><br>
          <div *ngIf="exchangeRate != 1"> سعر الصرف : <b
            style="color: #002c8a;">{{exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}} </b></div>
        </address>
      </div>
      <div class="col-4">
        <address style="font-size: 14px ;">
          <h3>تفاصیل الشحن:</h3>
          الاسم : <b
          style="color: #666666; margin-right: 5px">{{data?.address.first_name + ' ' + data?.address.last_name}}</b><br>
          الدولة : <b style="color: #666666; margin-right: 5px">{{data?.address?.country_language.name}}</b><br>
          المدینة : <b style="color: #666666; margin-right: 5px">{{data?.address.city.name}}</b><br>
          العنوان ١ : <b style="color: #666666; margin-right: 5px">{{data?.address.address1}}</b><br>
          العنوان ٢ : <b style="color: #666666; margin-right: 5px">{{data?.address.address1}}</b><br>
          رقم الهاتف : <b style="color: #666666; margin-right: 5px">{{data?.address.phone_mobile}}</b><br>
        </address>
      </div>

      <div class="col-4">
        <address style="font-size: 14px ;">
          <h3>معلومات العمیل:</h3>
          الاسم : <b
          style="color: #666666; margin-right: 5px">{{data?.member.first_name + ' ' + data?.member.last_name}}</b><br>
          البرید الالکتونی : <b style="color: #666666; margin-right: 5px">{{data?.member.email}}</b><br>
          رقم الهاتف : <b style="color: #666666; margin-right: 5px">{{data?.member.address }}</b><br>
          العنوان : <b style="color: #666666; margin-right: 5px">{{data?.member.phone}}</b><br>
        </address>
      </div>

    </div>
  </div>

  <div *ngSwitchCase="'TR'">

    <h1 style="color:#283592; font-size: 28px " class="ml-lg-3  ">Invoice</h1>
    <div class="row pl-lg-4" style="padding-left: 20px; padding-right: 20px ;  direction: {{alignText}}">
      <div class="col-4 text-left">
        <!--        <h3>Invoice </h3>-->
        <address style="font-size: 14px ;">
          No : <b style="color: #666666; margin-right: 5px">{{invoiceId}}</b><br>
          Date : <b style="color: #666666; margin-right: 5px">{{data.created_at | date}}</b><br>
          Payment : <b style="color: #666666; margin-right: 5px">{{data?.payment_method_language?.name }}</b><br>
          Status : <b [style.color]="!orderStatus && data?.current_state == 'canceled' ? 'red' : '#666666'" style=" margin-right: 5px;"                      class="text-capitalize">{{orderStatus ? orderStatus :data?.current_state }}</b><br>
          <div *ngIf="exchangeRate != 1"> Exchange Rate : <b style="color: #002c8a;">{{exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}} </b></div>
        </address>
      </div>
      <div class="col-4">
        <address style="font-size: 14px ;">
          <h3>Shipping Address :</h3>
          Name : <b  style="color: #666666; margin-right: 5px">{{data?.address.first_name + ' ' + data?.address.last_name}}</b><br>
          Country : <b style="color: #666666; margin-right: 5px">{{data?.address?.country_language.name}}</b><br>
          City : <b style="color: #666666; margin-right: 5px">{{data?.address.city.name}}</b><br>
          Address 1 : <b style="color: #666666; margin-right: 5px">{{data?.address.address1}}</b><br>
          Address 2 : <b style="color: #666666; margin-right: 5px">{{data?.address.address1}}</b><br>
          Phone  : <b style="color: #666666; margin-right: 5px">{{data?.address.phone_mobile}}</b><br>
        </address>
      </div>

      <div class="col-4">
        <address style="font-size: 14px ;">
          <h3>Customer info :</h3>
          Name : <b  style="color: #666666; margin-right: 5px">{{data?.member.first_name + ' ' + data?.member.last_name}}</b><br>
          Email  : <b style="color: #666666; margin-right: 5px">{{data?.member.email}}</b><br>
          Address  : <b style="color: #666666; margin-right: 5px">{{data?.member.address }}</b><br>
          Phone : <b style="color: #666666; margin-right: 5px">{{data?.member.phone}}</b><br>
        </address>
      </div>

    </div>

  </div>

  <div *ngSwitchDefault>
    <h1 style="color:#283592; font-size: 28px " class="ml-lg-3  ">Invoice</h1>
    <div class="row pl-lg-4" style="padding-left: 20px; padding-right: 20px ;  direction: {{alignText}}">
      <div class="col-4 text-left">
        <!--        <h3>Invoice </h3>-->
        <address style="font-size: 14px ;">
          No : <b style="color: #666666; margin-right: 5px">{{invoiceId}}</b><br>
          Date : <b style="color: #666666; margin-right: 5px">{{data.created_at | date}}</b><br>
          Payment : <b style="color: #666666; margin-right: 5px">{{data?.payment_method_language?.name }}</b><br>
          Status : <b [style.color]="!orderStatus && data?.current_state == 'canceled' ? 'red' : '#666666'" style=" margin-right: 5px;"   class="text-capitalize">{{orderStatus ? orderStatus : data?.current_state }}</b><br>
          <div *ngIf="exchangeRate != 1"> Exchange Rate : <b style="color: #002c8a;">{{exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}} </b></div>
        </address>
      </div>
      <div class="col-4">
        <address style="font-size: 14px ;">
          <h3>Shipping Address :</h3>
          Name : <b  style="color: #666666; margin-right: 5px">{{data?.address.first_name + ' ' + data?.address.last_name}}</b><br>
          Country : <b style="color: #666666; margin-right: 5px">{{data?.address?.country_language.name}}</b><br>
          City : <b style="color: #666666; margin-right: 5px">{{data?.address.city.name}}</b><br>
          Address 1 : <b style="color: #666666; margin-right: 5px">{{data?.address.address1}}</b><br>
          Address 2 : <b style="color: #666666; margin-right: 5px">{{data?.address.address1}}</b><br>
          Phone  : <b style="color: #666666; margin-right: 5px">{{data?.address.phone_mobile}}</b><br>
        </address>
      </div>

      <div class="col-4">
        <address style="font-size: 14px ;">
          <h3>Customer info :</h3>
          Name : <b  style="color: #666666; margin-right: 5px">{{data?.member.first_name + ' ' + data?.member.last_name}}</b><br>
          Email  : <b style="color: #666666; margin-right: 5px">{{data?.member.email}}</b><br>
          Address  : <b style="color: #666666; margin-right: 5px">{{data?.member.address }}</b><br>
          Phone : <b style="color: #666666; margin-right: 5px">{{data?.member.phone}}</b><br>
        </address>
      </div>

    </div>
  </div>
</div>

