<div [ngSwitch]="countryCode">
  <div *ngSwitchCase="'IQ'">
    <div class="row mb-lg-1" style=" border-top: 8px #283592 solid; padding: 20px; margin-bottom: 10px">
      <div class="col-6">
        <address style="color: #177dc1; font-size: 15px">
          <h2 style="color: #177dc1; font-weight: bold">UNIMART GLOBAL</h2>
          <h5 style="color: #39b86b; font-weight: bold">شركة نحو الافق للتجارة الالكترونية المحدودة </h5>

          <span style="display: flex;  align-items: center;"><i class="bx bx-map"
                                                                style="color: #39b86b; font-size: 24px"></i>           شارع ١٤ رمضان - المنصور - بغداد</span>
          <span style="display: flex;  align-items: center; float: left ; margin-right: 20px ; margin-top: 10px"><i
            class="bx bx-globe" style="color: #39b86b; font-size: 24px"></i>  Website: www.unimartglobl.com</span>
          <span style="display: flex;  align-items: center;; margin-top: 10px"><i class="bx bx-envelope"
                                                                                  style="color: #39b86b; font-size: 24px"></i> info@unimartglobal.com</span>

        </address>
      </div>
      <div class="col-6 text-end">
        <img class="text-sm-end" src="../../../../../../assets/images/logo-invoice.png" alt="logo" height="120"/>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'TR'">
    <div class="row mb-lg-1" style=" border-top: 8px #283592 solid; padding: 20px; margin-bottom: 10px">
      <div class="col-6">
        <address style="color: #177dc1; font-size: 15px">
          <h2 style="color: #177dc1; font-weight: bold">UNIMART GLOBAL</h2>
          <h5 style="color: #39b86b; font-weight: bold">United Markets Teknoloji Sanayi İç ve Dış Ticaret Ltd Şti</h5>

          <span style="display: flex;  align-items: center;"><i class="bx bx-map"
                                                                style="color: #39b86b; font-size: 24px"></i>          Maslak Mahallesi Stone Clover Sokak T4 <br>NO: 1 / U B246 Sarıyer / İSTANBUL</span>
          <span style="display: flex;  align-items: center; float: left ; margin-right: 20px ; margin-top: 10px"><i
            class="bx bx-globe" style="color: #39b86b; font-size: 24px"></i>  Website: www.unimartglobl.com</span>
          <span style="display: flex;  align-items: center;; margin-top: 10px"><i class="bx bx-envelope"
                                                                                  style="color: #39b86b; font-size: 24px"></i> info@unimartglobal.com</span>

        </address>
      </div>
      <div class="col-6 text-end">
        <img class="text-sm-end" src="../../../../../../assets/images/logo-invoice.png" alt="logo" height="120"/>
      </div>
    </div>
  </div>

  <div *ngSwitchDefault>
    <div class="row mb-lg-1" style=" border-top: 8px #283592 solid; padding: 20px; margin-bottom: 10px">
      <div class="col-6">
        <address style="color: #177dc1; font-size: 15px">
          <h2 style="color: #177dc1; font-weight: bold">UNIMART GLOBAL <span style="font-size: medium">FZ LLC,</span></h2>
          <h5 style="color: #39b86b; font-weight: bold"><br></h5>

          <span style="display: flex;  align-items: center;"><i class="bx bx-map"
                                                                style="color: #39b86b; font-size: 24px"></i>          Internet city, Dubai, UAE</span>
          <span style="display: flex;  align-items: center; float: left ; margin-right: 20px ; margin-top: 10px"><i
            class="bx bx-globe" style="color: #39b86b; font-size: 24px"></i>  Website: www.unimartglobl.com</span>
          <span style="display: flex;  align-items: center;; margin-top: 10px"><i class="bx bx-envelope"
                                                                                  style="color: #39b86b; font-size: 24px"></i> info@unimartglobal.com</span>

        </address>
      </div>
      <div class="col-6 text-end">
        <img class="text-sm-end" src="../../../../../../assets/images/logo-invoice.png" alt="logo" height="120"/>
      </div>
    </div>
  </div>
</div>
