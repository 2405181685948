import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'order-invoice-footer',
  templateUrl: './order-invoice-footer.component.html',
  styleUrls: ['./order-invoice-footer.component.scss']
})
export class OrderInvoiceFooterComponent implements OnInit {

  @Input() countryCode ;
  alignText  = 'ltr';
  constructor() {

  }

  ngOnInit(): void {
    if(this.countryCode == 'IQ'){
      this.alignText = 'rtl';
    }
  }

}
