import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.scss']
})
export class OrderInvoiceComponent implements OnInit {

  @Input() data ;
  @Input() products ;
  @Input() exchangeRate ;
  @Input() detailsFunds = true ;
  @Input() orderStatus=null ;
  @Input() backUrl ;

  constructor( private router: Router,) { }

  ngOnInit(): void {
  }

  handleBackUrl(): void{
    this.router.navigate([this.backUrl ]);
  }
}
