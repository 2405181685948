import {Injectable} from '@angular/core';


import {User} from '../models/auth.models';
import {map} from "rxjs/operators";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

import {JwtHelperService} from "@auth0/angular-jwt";
import {PermissionService} from "../permissions/permission.service/permission.service";

@Injectable({providedIn: 'root'})

export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUserValue: Observable<User>;
  private jwtHelper: JwtHelperService;
  private token: string;

  constructor(private http: HttpClient, private permissionsService: PermissionService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('Admin-User')));
    this.currentUserValue = this.currentUserSubject.asObservable();
    this.jwtHelper = new JwtHelperService()
    this.token = localStorage.getItem('Admin-Access_Token')

  }

  /**
   * Returns the current user
   */
  public currentUser(): User {
    return this.currentUserSubject.value;
  }

  public get getAuthUserValue(): User {
    return this.currentUserSubject.value;
  }

  public loadPermissions(): void {
    this.token = localStorage.getItem('Admin-Access_Token')
    const tokenData = this.jwtHelper.decodeToken(this.token)
    this.permissionsService.define(tokenData.scopes)

  }

  public isTokenExpired(): boolean {
    return this.jwtHelper.isTokenExpired(this.token)
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string, rememberMe: boolean = false) {
    return this.http.post<any>(`/users/authenticate`, {email, password, rememberMe})
      .pipe(map(resp => {
        // login successful if there's a jwt token in the response
        if (resp && resp.status == 200) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          const token = resp.data.token;
          delete (resp.data.token)
          localStorage.setItem('Admin-User', JSON.stringify(resp.data));
          localStorage.setItem('Admin-Access_Token', token);
          this.loadPermissions()
          this.currentUserSubject.next(resp.data);
        }
        return resp;
      }));
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(email: string, password: string) {
    // return getFirebaseBackend().registerUser(email, password).then((response: any) => {
    //     const user = response;
    //     return user;
    // });
    return this.http.post<any>(`/users/authenticate`, {email, password})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('Admin-User', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      }));
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    // return getFirebaseBackend().forgetPassword(email).then((response: any) => {
    //     const message = response.data;
    //     return message;
    // });
    return this.http.post<any>(`/users/authenticate`, {email})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('Admin-User', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      }));
  }

  /**
   * Logout the user
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('Admin-User');
    localStorage.removeItem('Admin-Access_Token');
    this.currentUserSubject.next(null);
  }
}

