import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'order-invoice-table',
  templateUrl: './order-invoice-table.component.html',
  styleUrls: ['./order-invoice-table.component.scss']
})
export class OrderInvoiceTableComponent implements OnInit {
  @Input() data ;
  @Input() products ;
  @Input() exchangeRate ;
  @Input() orderStatus ;
  @Input() detailsFunds ;
  @Input() countryCode = 'IQ';
  alignText  = 'ltr';

  constructor() { }

  ngOnInit(): void {

    if(this.countryCode == 'IQ'){
      this.alignText = 'rtl';
    }
  }
  getTotalProducts(products: any[]): number {
    let total = 0;
    products.forEach((row)=>{
      total += row.product_price * row.product_quantity
    });
    return total ;
  }
}
