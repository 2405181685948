import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-summary-node-tree',
  templateUrl: './summary-node-tree.component.html',
  styleUrls: ['./summary-node-tree.component.scss']
})
export class SummaryNodeTreeComponent implements OnInit {

  @Input() member;
  imgUrl: string = environment.imgUrl + '/users/';
  constructor() { }

  ngOnInit(): void {
  }

}
