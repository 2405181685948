
<div id="layout-wrapper">

  <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
  </app-topbar>

  <app-sidebar [isCondensed]="isCondensed"></app-sidebar>

  <div class="main-content">
    <div class="page-content">
      <app-loader></app-loader>
<!--      <div id="preloader" style="background-color: rgb(82 82 82 / 40%) !important;">-->
<!--        <div id="status">-->
<!--          <div class="spinner-chase">-->
<!--            <div class="chase-dot"></div>-->
<!--            <div class="chase-dot"></div>-->
<!--            <div class="chase-dot"></div>-->
<!--            <div class="chase-dot"></div>-->
<!--            <div class="chase-dot"></div>-->
<!--            <div class="chase-dot"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <!-- content -->
      <router-outlet></router-outlet>
    </div>

    <!-- footer -->
    <app-footer></app-footer>
  </div>
</div>

<app-rightsidebar></app-rightsidebar>
