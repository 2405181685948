
<!--<ng-template  *ngFor="let node of nodes"  >-->


<!--      <app-children-nodes-tree    [node]="node" [rootId]="rootId"></app-children-nodes-tree>-->

<!--</ng-template>-->

<ul>
  <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ nodes: nodes }"  ></ng-container>
</ul>

<ng-template #recursiveListTmpl let-nodes="nodes" let-checkNode="checkNode">
  <li *ngFor="let item of nodes">
    <app-node-tree [node]="item"  [rootId]="rootId"   ></app-node-tree>
    <ul *ngIf="item.has_child == 1">
      <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ nodes: item.children }"></ng-container>
    </ul>
    <ul *ngIf="item.has_child == 0 && item.is_active_leg == 1">
      <li>
        <img class='tree_icon add-icon'
             src="/assets/images/tree/add-user.png"
             onclick='goToLink(item["node_id"]+"-"+item["business_center"], "left");'/><br>
      </li>
      <li>
        <img class='tree_icon add-icon'
             src="/assets/images/tree/add-user.png"
             onclick='goToLink(item["node_id"]+"-"+item["business_center"], "right");'/><br>
      </li>
    </ul>
  </li>
</ng-template>
