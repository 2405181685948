import {Component, Inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(@Inject(DOCUMENT) private document: any, private _cookiesService: CookieService) {

    let theme = 'Light';
    let lang = this._cookiesService.get('lang') ;
    if(!lang){
      lang = 'en';
      this._cookiesService.set('lang', lang);
    }
    if(this._cookiesService.check('theme')) {
     theme = this._cookiesService.get('theme');
    }else{
      this._cookiesService.set('theme', theme);
    }

    const existingBootstrapLink = this.document.getElementById('bootstrapCss') as HTMLLinkElement;
    const existingAppLink = this.document.getElementById('langCss') as HTMLLinkElement;
    const bundleApp = lang === 'ar' ? 'rtl' + theme + 'App.css' : 'ltr' + theme + 'App.css';
    const bundleBootstrap = theme + 'Bootstrap.css';

    // if (existingBootstrapLink) {
    //   existingBootstrapLink.href = bundleBootstrap;
    // } else {
    //   this.addStyleLink(bundleBootstrap);
    // }
    // if (existingAppLink){
    //   existingAppLink.href = bundleApp;
    // } else {
    //   this.addStyleLink(bundleApp);
    // }
  }

  private addStyleLink(bundleBootstrap: string) {
    const headTag = this.document.getElementsByTagName('head')[0] as HTMLHeadElement;
    const newLink = this.document.createElement('link');
    newLink.rel = 'stylesheet';
    newLink.type = 'text/css';
    newLink.id = 'bootstrapCss';
    newLink.href = bundleBootstrap;
    headTag.appendChild(newLink);
  }
}
