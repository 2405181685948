import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {PaginateModels, ResponseModal} from "../models/paginate.models";
import {Observable} from "rxjs";
import {Member} from "../models/network.models";

@Injectable({ providedIn: 'root' })
export class GenealogyTreeService {

  private baseUrl = `/network/genealogy/tree`;

  constructor(private http: HttpClient) { }

  getTreeById(id): Observable<ResponseModal<any[]>> {
    let query = '';
    if(id){
       query = '?uId=' + id.toString()
    }
    return this.http.get<ResponseModal<Member[]>>(this.baseUrl + query) ;
  }
  getBinaryTreeById(id, depth): Observable<ResponseModal<any[]>> {
    let query = '';
    if(id){
       query = '/binary?uId=' + id.toString() + '&depth=' + depth.toString()
    }
    return this.http.get<ResponseModal<Member[]>>(this.baseUrl + query) ;
  }

  getTeamTreeById(id, depth): Observable<ResponseModal<any[]>> {
    let query = '';
    if(id){
      query = '/team?uId=' + id.toString() + '&depth=' + depth.toString()
    }
    return this.http.get<ResponseModal<Member[]>>(this.baseUrl + query) ;
  }

  getRankTreeById(id, type): Observable<ResponseModal<any[]>> {
    let query = '';
    if(id){
      query = '/rank?uId=' + id.toString() + '&type=' + type
    }
    return this.http.get<ResponseModal<Member[]>>(this.baseUrl + query) ;
  }

  getExpandTreeById(id): Observable<ResponseModal<any[]>> {
    let query = '';
    if(id){
       query = '?uId=' + id.toString()
    }
    return this.http.get<ResponseModal<Member[]>>(this.baseUrl + '/expandTree' + query) ;
  }


  store(model: Member): Observable<ResponseModal<Member>> {
    if(model.id > 0 ) {
      return this.http.put<ResponseModal<Member>>(`${this.baseUrl}/${model.id}`, model);
    }else {
      return this.http.post<ResponseModal<Member>>(this.baseUrl, model);
    }
  }
}
