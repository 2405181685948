<div  [ngSwitch]="countryCode">
<div *ngSwitchCase="'IQ'" >
  <div class=""  style="padding-right: 20px;margin-bottom: 50px;margin-right: 100px; padding-left: 20px; direction: {{alignText}} ; vertical-align: center">
    <h4 class="font-size-15 font-weight-bold">-------</h4>
    <h4 class="font-size-15 font-weight-bold">   توقيع العميل</h4>

  </div>
  <div class=""  style="padding: 20px;font-size: 18px; border: 1px dashed gray; direction: {{alignText}}; ">
    <ul>
      <li> <p>
        بمجرد شراء المنتجات يعتبر الزبون ممثلا ً مستقلا لشركة (GLOBAL UNIMART) في حال أراد الزبون ذلك
        وبموجبه يحق له الاستفادة من نظام العمولات المقدم من قبل شركة GLOBAL UNIMART
        وهو نظام تسويق بالعمولة<b> ولا يمثل أي نوع من أنواع الوظيفة ولا يوجد أي راتب ثابت.</b>
      </p>
      </li>
      <li><p>        بإمكانك استبدال المنتجات خلال 30 يوم من تاريخ الشراء, شرط أن تكون المنتجات المستبدلة صالحة للاستبدال.
      </p></li>
      <li> <p>بإمكانك استرجاع 50% كحد أقصى من كمية المنتجات الموجودة في الفاتورة,  استرجاعا كاملا خلال 14 يوم من تاريخ الشراء والاحتفاظ ب 50% من كمية المنتجات وتصبح غير قابلة للاسترجاع . بشرط أن تكون المنتجات المسترجعة صالحة للاسترجاع .
      </p></li>
      <li><p>        حسب سياسات الشركة للاسترجاع يتكفل الزبون برسوم الاستلام والمناقلة والخدمات المحلية ومقدارها 12% من قيمة الفاتورة وهي غير قابلة للاسترجاع .
      </p></li>
      <li><p>        لا يشمل الإسترجاع مبالغ الخدمات المحلية ولا يشمل مصاريف نقل المنتجات ولا يشمل رسوم بوابات الدفع الإلكترونية.      </p></li>
      <li><p>لا يمكن استرجاع او استبدال المنتجات الموجودة في الفاتورة عند الدفع عن طريق محفظة النقاط. </p></li>
    </ul>

  </div>

</div>


<div *ngSwitchCase="'TR'">
  <div class=""
       style="padding-right: 20px;margin-bottom: 50px;margin-right: 100px; padding-left: 20px;text-align: center ; width: 400px ">
    <h4 class="font-size-15 font-weight-bold">-------</h4>
    <h4 class="font-size-15 font-weight-bold"> Customer Signature</h4>

  </div>
  <div class="" style="padding: 20px;font-size: 18px; border: 1px dashed gray;  ">
    <ul>
      <li><p>
        Ürünler satın alındıktan sonra, müşteri, istediği takdirde (UNIMART GLOBAL)'in bağımsız temsilcisi olarak kabul edilir ve buna bağlı olarak pazarlama komisyonu sistemi olan ( UNIMART GLOBAL -  UNİTED MARKETS TEKNOLOJİ SANAYİ İÇ VE DIŞ) tarafından sağlanan komisyon sisteminden yararlanmaya hak kazanır; <b>herhangi bir istihdam türünü temsil etmemektedir ve sabit bir maaş yoktur</b>.
      </p></li>
      <li><p>
        Değiştirilebilen tüm ürünlerin değiştirilmeye uygun olması koşuluyla, ürünleri satın alma tarihinden itibaren 30 gün içinde değiştirebilirsiniz.

      </p></li>
      <li><p>
        Faturadaki ürünlerin maksimum %50'ını eksiksiz olarak, satın alma tarihinden itibaren 14 gün içinde iade edebilir ve iade edilmeyen ürünlerin %50'ını elinizde tutabilirsiniz. İade edilen tüm ürünlerin iade için uygun olması şartı esastır.

      </p>
      </li>
      <li><p>
        Firmanın iade politikalarına göre fatura tutarının %12'si olan teslim alma ve nakliye ücreti müşteriye aittir ve iade tutarından düşülür.


      </p></li>
      <li><p>        Geri ödeme, yerel hizmetlerin miktarlarını, ürünlerin nakliye maliyetlerini ve elektronik ödeme ağ geçitlerinin ücretlerini içermez.     </p></li>
      <li><p>        Puan cüzdanınızı kullanarak ödeme yaptığınızda ürünler iade edilemez veya değiştirilemez.    </p></li>
    </ul>


  </div>
</div>

<div *ngSwitchDefault>
  <div class=""
       style="padding-right: 20px;margin-bottom: 50px;margin-right: 100px; padding-left: 20px;text-align: center ; width: 400px ">
    <h4 class="font-size-15 font-weight-bold">-------</h4>
    <h4 class="font-size-15 font-weight-bold"> Customer Signature</h4>

  </div>
  <div class="" style="padding: 20px;font-size: 18px; border: 1px dashed gray;  ">
    <ul>
      <li><p>
        Once the products are purchased, the customer is considered an independent representative of UNIMART
        GLOBAL (in case the customer wants to) and accordingly he is entitled to benefit from the commission
        system provided by UNIMART GLOBAL, which is a marketing commission system that <b>does not represent any
        type of employment and there is no fixed salary</b>.
      </p></li>
      <li><p>
        You can replace products within 30 days from the date of purchase,  Provided that all replaced products are eligible for replacement.
      </p></li>
      <li><p>
        You can return a maximum of 50% of products on the invoice in full within 14 days from the time of purchase and keep 50% of the products that are not refundable. Provided that all returned products are eligible for return.
      </p>
      </li>
      <li><p>
        According to the company's return policies, the customer bears the receiving and transportation fees which is 12% invoice amount, and they are deducted from the refund amount.
      </p></li>
      <li><p>     The refund does not include the amounts of local services, costs of transporting products, and fees of electronic payment gateways.     </p></li>
      <li><p> Products are not refundable or replaceable when you pay using your point wallet.   </p></li>
    </ul>


  </div>
</div>
</div>
