<li>
  <app-node-tree [node]="node"  [rootId]="rootId" ></app-node-tree>


  <ul *ngIf="node['children'] && node['children'].length > 0">
<h3>{{node['children'].length}}</h3>
    <ng-template *ngFor="let chNode of node['children'] ">

      <app-parent-nodes-tree  [nodes]="[chNode]" [rootId]="rootId"></app-parent-nodes-tree>
    </ng-template>
<!--    <app-parent-nodes-tree *ngFor="let chNode of node['children']" [nodes]="[chNode]" [rootId]="rootId"></app-parent-nodes-tree>-->
  </ul>
  <ul *ngIf="node['has_child'] == 0 && node['is_active_leg'] == 1">
    <li>
      <img class='tree_icon add-icon'
           src="/assets/images/tree/add-user.png"
           onclick='goToLink(node["node_id"]+"-"+node["business_center"], "left");'/><br>
    </li>
    <li>
      <img class='tree_icon add-icon'
           src="/assets/images/tree/add-user.png"
           onclick='goToLink(node["node_id"]+"-"+node["business_center"], "right");'/><br>
    </li>
  </ul>

</li>
