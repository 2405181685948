import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {GenealogyTreeService} from "../../../../core/services/genealogy-tree.service";

declare var $;

@Component({
  selector: 'app-node-tree',
  templateUrl: './node-tree.component.html',
  styleUrls: ['./node-tree.component.scss']
})
export class NodeTreeComponent implements OnInit ,AfterViewInit{
  imgUrl: string = environment.imgUrl + '/users/';
  @Input() node;
  @Input() rootId: number;
  username: string[];
  email: string;
  title: string;
  nodeId: string;
  showDownIcon: boolean;
  showUpIcon: boolean;
  isRoot: boolean;
  align: string;
  avatarImg: string;
  constructor( private elementRef:ElementRef,
               private genealogyTreeService: GenealogyTreeService
               ) { }

  ngAfterViewInit() {

    // @ts-ignore
    // window.expandGenealogyTree = (e) => {
    //   console.log(e)
    //   let username = e.target.getAttribute('node-id')
    //   this.expandGenealogyTree(username, 69544)
    // }

  }
  onClick(event) {
    console.log(event);
  }


  ngOnInit(): void {
    if(this.node){
      if(this.node['business_center'] == '001') {
        this.email = this.node['user']['email'];
        this.username = this.email.split('@');
        this.title = this.username[0] +  '-' + this.node['business_center'];
      }else{
        this.title =  this.node['business_center'];
      }
      this.nodeId = this.node['node_id'] +  '_' + this.node['business_center'];
      this.showDownIcon = (this.node['has_child'] == 1 && !this.node['children']) ? true : false;
      this.showUpIcon = (this.rootId == this.node['node_id']) && this.rootId != this.node.node_id  && (this.node['business_center'] == '001') ? true : false;
      this.isRoot = this.node['node_id'] == this.rootId && this.node['business_center'] == 0 ? true : false;
      this.align = this.node['business_center'] != '001' ? '-' + this.node['alignment_tree'] : '';
      if(this.node['business_center'] == '001'){
        this.avatarImg = this.imgUrl + this.node['user']['avatar'];
      }else {
        this.avatarImg = '/assets/images/users/avatar' + this.align + '.png';
      }
    }
  }

  checkNode(): void{
    console.log('Click check node')
  }


}
