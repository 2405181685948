import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbTimepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import {ClickOutsideModule} from 'ng-click-outside';

import {PagetitleComponent} from './pagetitle/pagetitle.component';
import {LoaderComponent} from './loader/loader.component';
import {NodeTreeComponent} from './tree/node-tree/node-tree.component';
import {ChildrenNodesTreeComponent} from './tree/children-nodes-tree/children-nodes-tree.component';
import {ParentNodesTreeComponent} from './tree/parent-nodes-tree/parent-nodes-tree.component';
import {SummaryNodeTreeComponent} from './tree/summary-node-tree/summary-node-tree.component';
import {TranslateModule} from "@ngx-translate/core";
import {OrderInvoiceComponent} from './order-invoice/order-invoice.component';
import {OrderInvoiceFooterComponent} from './order-invoice/components/order-invoice-footer/order-invoice-footer.component';
import {OrderInvoiceHeaderComponent} from './order-invoice/components/order-invoice-header/order-invoice-header.component';
import {OrderInvoiceInformationComponent} from './order-invoice/components/order-invoice-information/order-invoice-information.component';

import {OrderInvoiceTableComponent} from './order-invoice/components/order-invoice-table/order-invoice-table.component';

import {NgSelectModule} from "@ng-select/ng-select";


@NgModule({
  declarations: [PagetitleComponent, LoaderComponent, NodeTreeComponent, ChildrenNodesTreeComponent, ParentNodesTreeComponent, SummaryNodeTreeComponent, OrderInvoiceComponent, OrderInvoiceFooterComponent, OrderInvoiceHeaderComponent, OrderInvoiceInformationComponent,  OrderInvoiceTableComponent,  ],
  imports: [
    CommonModule,
    FormsModule,
    ClickOutsideModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    TranslateModule,
    NgSelectModule
  ],
    exports: [PagetitleComponent, LoaderComponent, NodeTreeComponent, SummaryNodeTreeComponent, ParentNodesTreeComponent, OrderInvoiceComponent, OrderInvoiceHeaderComponent, OrderInvoiceInformationComponent, OrderInvoiceFooterComponent, ]
})
export class UIModule {
}
