import {MenuItem} from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true
  },
  {
    id: 2,
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    icon: 'bx-home-circle',
    badge: {
      variant: 'info',
      text: 'MENUITEMS.DASHBOARDS.BADGE',
    },
    subItems: [
      {
        id: 3,
        label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
        link: '/dashboard',
        parentId: 2
      },
      {
        id: 4,
        label: 'MENUITEMS.DASHBOARDS.LIST.SAAS',
        link: '/dashboards/saas',
        parentId: 2
      }
    ]
  },
  {
    id: 6,
    isLayout: false
  },
  {
    id: 7,
    label: 'MENUITEMS.STORE.TEXT',
    isTitle: true
  },
  {
    id: 8,
    label: 'MENUITEMS.SETTING.TEXT',
    permissions: ['SETTING'],
    icon: 'bx-buoy',
    subItems: [
      {
        id: 9,
        label: 'MENUITEMS.SETTING.LIST.USERS',
        link: '/setting/users',
        permissions: ['USER'],
        parentId: 8
      },
      {
        id: 10,
        label: 'MENUITEMS.SETTING.LIST.GROUPS',
        permissions: ['GROUP'],
        link: '/setting/groups',
        parentId: 8
      },
      {
        id: 11,
        label: 'MENUITEMS.SETTING.LIST.LANGUAGES',
        permissions: ['LANGUAGE'],
        link: '/setting/languages',
        parentId: 8
      },
      {
        id: 12,
        label: 'MENUITEMS.SETTING.LIST.TRANSLATOR.TEXT',
        permissions: ['TRANSLATOR'],
        parentId: 8,
        subItems: [
          {
            id: 1,
            label: 'MENUITEMS.SETTING.LIST.TRANSLATOR.USER',
            link: '/setting/translators/user',
            permissions: ['TRANSLATOR_USER'],
            parentId: 12,
          },
          {
            id: 2,
            label: 'MENUITEMS.SETTING.LIST.TRANSLATOR.STORE',
            link: '/setting/translators/store',
            permissions: ['TRANSLATOR_STORE'],
            parentId: 12,
          },
          {
            id: 3,
            label: 'MENUITEMS.SETTING.LIST.TRANSLATOR.OAUTH',
            link: '/setting/translators/oauth',
            permissions: ['TRANSLATOR_OAUTH'],
            parentId: 12,
          }
        ]
      },
      {
        id: 13,
        label: 'MENUITEMS.SETTING.LIST.CURRENCIES',
        link: '/setting/currencies',
        permissions: ['CURRENCY'],
        parentId: 8
      },
      {
        id: 14,
        label: 'MENUITEMS.SETTING.LIST.BUSINESS_VOLUMES_ANALYTICS',
        link: '/setting/business_volumes_analytics',
        permissions: ['BUSINESS_VOLUMES_ANALYTICS'],
        parentId: 8
      },
      {
        id: 15,
        label: 'MENUITEMS.SETTING.LIST.BUSINESS_VOLUMES',
        permissions: ['BUSINESS_VOLUME'],
        link: '/setting/business_volumes',
        parentId: 8
      },
      {
        id: 16,
        label: 'MENUITEMS.SETTING.LIST.PACKAGE_PROFIT_PLAN',
        permissions: ['PACKAGE_PROFIT_PLAN'],
        link: '/setting/package_profit_plans',
        parentId: 8
      },
      {
        id: 17,
        label: 'MENUITEMS.SETTING.LIST.PACKAGE_PLAN',
        permissions: ['PACKAGE_PLAN'],
        link: '/setting/package_plans',
        parentId: 8
      },
      {
        id: 18,
        label: 'MENUITEMS.SETTING.LIST.PAYMENT_METHOD',
        permissions: ['PAYMENT_METHOD'],
        link: '/setting/payment_methods',
        parentId: 8
      },
      {
        id: 19,
        label: 'MENUITEMS.SETTING.LIST.SHIPPING_METHOD',
        permissions: ['SHIPPING_METHOD'],
        link: '/setting/shipping_methods',
        parentId: 8
      },
      {
        id: 20,
        label: 'MENUITEMS.SETTING.LIST.TAXES_ROLES',
        permissions: ['TAXES_ROLE'],
        link: '/setting/taxes',
        parentId: 8
      },
      {
        id: 21,
        label: 'MENUITEMS.SETTING.LIST.GENERATION_RANK',
        permissions: ['RANK'],
        link: '/setting/ranks',
        parentId: 8
      },
      {
        id: 22,
        label: 'MENUITEMS.SETTING.LIST.CONTRACT_TEMPLATE',
        permissions: ['CONTRACT_TEMPLATE'],
        link: '/setting/contract_templates',
        parentId: 8
      },
      {
        id: 23,
        label: 'MENUITEMS.SETTING.LIST.CONFIGURATION',
        permissions: ['APP_CONFIGURATION'],
        link: '/setting/configuration',
        parentId: 8
      },
      {
        id: 24,
        label: 'MENUITEMS.SETTING.LIST.SETTING_NOTIFICATION',
        permissions: ['APP_CONFIGURATION'],
        link: '/setting/setting_notifications',
        parentId: 8
      },
      {
        id: 25,
        label: 'MENUITEMS.SETTING.LIST.EXTRA_FEES',
        permissions: ['EXTRA_FEES'],
        link: '/setting/extra_fees',
        parentId: 8
      }

    ]
  },
  {
    id: 20,
    label: 'MENUITEMS.LOCATION.TEXT',
    permissions: ['LOCATION'],
    icon: 'bx-navigation',
    subItems: [
      {
        id: 21,
        label: 'MENUITEMS.LOCATION.LIST.ZONES',
        link: '/location/zones',
        permissions: ['ZONE'],
        parentId: 20
      },
      {
        id: 22,
        label: 'MENUITEMS.LOCATION.LIST.COUNTRIES',
        permissions: ['COUNTRY'],
        link: '/location/countries',
        parentId: 20
      },
      {
        id: 23,
        label: 'MENUITEMS.LOCATION.LIST.CITIES',
        permissions: ['CITY'],
        link: '/location/cities',
        parentId: 20
      }

    ]
  },
  {
    id: 30,
    label: 'MENUITEMS.CATALOG.TEXT',
    permissions: ['CATALOG'],
    icon: 'bx-store-alt',
    subItems: [
      {
        id: 31,
        label: 'MENUITEMS.CATALOG.LIST.CATEGORIES',
        link: '/catalog/categories',
        permissions: ['CATEGORY'],
        parentId: 30
      },
      {
        id: 32,
        label: 'MENUITEMS.CATALOG.LIST.GROUP_ATTRIBUTES',
        link: '/catalog/group_attributes',
        permissions: ['ATTRIBUTE_GROUP'],
        parentId: 30
      },
      {
        id: 33,
        label: 'MENUITEMS.CATALOG.LIST.ATTRIBUTES',
        link: '/catalog/attributes',
        permissions: ['ATTRIBUTE'],
        parentId: 30
      },
      {
        id: 34,
        label: 'MENUITEMS.CATALOG.LIST.BRANDS',
        permissions: ['BRAND'],
        link: '/catalog/brands',
        parentId: 30
      },
      {
        id: 35,
        label: 'MENUITEMS.CATALOG.LIST.MANUFACTURERS',
        link: '/catalog/manufacturers',
        permissions: ['MANUFACTURER'],
        parentId: 30
      },
      {
        id: 36,
        label: 'MENUITEMS.CATALOG.LIST.PRODUCTS',
        permissions: ['PRODUCT'],
        link: '/catalog/products',
        parentId: 30
      }

    ]
  },
  {
    id: 40,
    label: 'MENUITEMS.SHOP.TEXT',
    permissions: ['SHOP'],
    icon: 'bx-cart-alt',
    subItems: [
      {
        id: 41,
        label: 'MENUITEMS.SHOP.LIST.LAYOUTS',
        permissions: ['LAYOUT'],
        link: '/shop/layouts',
        parentId: 40
      },
    ]
  },
  {
    id: 50,
    label: 'MENUITEMS.NETWORK.TEXT',
    permissions: ['NETWORK'],
    icon: 'bx-sitemap',
    subItems: [
      // {
      //   id: 51,
      //   label: 'MENUITEMS.NETWORK.LIST.GENEALOGY_TREE',
      //   permissions: ['MEMBER_GENEALOGY_TREE'],
      //   link: '/network/genealogy_tree',
      //   parentId: 50
      // },
      {
        id: 52,
        label: 'MENUITEMS.NETWORK.LIST.MEMBERS',
        permissions: ['MEMBER'],
        link: '/network/members',
        parentId: 50
      },
      {
        id: 53,
        label: 'MENUITEMS.NETWORK.LIST.KYC_MEMBERS',
        permissions: ['MEMBER_KYC'],
        link: '/network/kyc_members',
        parentId: 50
      },
      {
        id: 54,
        label: 'MENUITEMS.NETWORK.LIST.GOLDEN_SHARE_MEMBERS',
        permissions: ['MEMBER_GOLDEN_SHARE'],
        link: '/network/golden_share_members',
        parentId: 50
      },
    ]
  },
  {
    id: 60,
    label: 'MENUITEMS.SHOPPING.TEXT',
    permissions: ['SHOPPING'],
    icon: 'bx-cart',
    subItems: [
      {
        id: 61,
        label: 'MENUITEMS.SHOPPING.LIST.ORDERS',
        permissions: ['ORDER'],
        link: '/shopping/orders',
        parentId: 60
      },
      {
        id: 62,
        label: 'MENUITEMS.SHOPPING.LIST.ORDERS_PAID',
        permissions: ['ORDER_PAID'],
        link: '/shopping/orders-paid',
        parentId: 60
      },
      {
        id: 64,
        label: 'MENUITEMS.SHOPPING.LIST.ORDERS_REFUNDING',
        permissions: ['ORDER_REFUNDING'],
        link: '/shopping/orders-refunding',
        parentId: 60
      }
    ]
  },
  {
    id: 70,
    label: 'MENUITEMS.ACCOUNT.TEXT',
    permissions: ['ACCOUNT'],
    icon: 'bx-wallet',
    subItems: [
      {
        id: 71,
        label: 'MENUITEMS.ACCOUNT.LIST.TRANSACTIONS',
        permissions: ['ACCOUNTING_USER_TRANSACTION'],
        link: '/accounting/users/transactions',
        parentId: 70
      },
      {
        id: 72,
        label: 'MENUITEMS.ACCOUNT.LIST.WITHDRAWAL_TRANSACTIONS',
        permissions: ['ACCOUNTING_WITHDRAWAL_TRANSACTION'],
        link: '/accounting/withdrawal/transactions',
        parentId: 70
      },
      {
        id: 73,
        label: 'MENUITEMS.ACCOUNT.LIST.MASTER_CARDS',
        permissions: ['ACCOUNTING_MASTER_CARD'],
        link: '/accounting/master_cards',
        parentId: 70
      },
    ]
  },

  {
    id: 80,
    label: 'Reports',
    permissions: ['REPORTS'],
    icon: 'bxs-bar-chart-alt-2',
    subItems: [
      {
        id: 81,
        label: 'Business Volume',
        permissions: ['REPORTS_BUSINESS_VOLUME'],
        link: '/reports/business_volume',
        parentId: 80
      },
      {
        id: 81,
        label: 'Generation BV',
        permissions: ['REPORTS_GENERATION_BUSINESS_VOLUME'],
        link: '/reports/rank_business_volume',
        parentId: 80
      },
    ]
  },
  {
    id: 90,
    label: 'MENUITEMS.MONITORING.TEXT',
    permissions: ['MONITORING'],
    icon: 'bx-analyse',
    subItems: [
      {
        id: 91,
        label: 'MENUITEMS.MONITORING.LIST.ODOO_ERROR_TRANSACTIONS',
        permissions: ['MONITORING_TRANSACTION_ODOO_ERROR'],
        link: '/monitoring/odoo_transactions',
        parentId: 90
      },
      {
        id: 92,
        label: 'MENUITEMS.MONITORING.LIST.ODOO_ERROR_ORDERS',
        permissions: ['MONITORING_ORDER_ODOO_ERROR'],
        link: '/monitoring/odoo_orders',
        parentId: 90
      },
    ]
  },
];

