import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";
import {ExceptPermissionDirective} from "./except-permission.directive";
import {HasPermissionDirective} from "./has-permission.directive";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [
    HasPermissionDirective,
    ExceptPermissionDirective,

  ],
  exports: [
    HasPermissionDirective,
    ExceptPermissionDirective,

  ],
  entryComponents: [
  ]

})
export class Ng2xHasPermissionModule {}
