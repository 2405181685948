<div *ngIf="loading" id="preloader"  style="background-color: rgb(82 82 82 / 40%) !important;">
  <div id="status">
    <div class="spinner-chase">
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
    </div>
  </div>
</div>
