import {Inject, Injectable} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';




import { environment } from '../../../environments/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  baseUrl: string
  constructor() {
    this.baseUrl = environment.apiUrl;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let apiReq = req;
    if(!req.url.includes('assets')) {
       apiReq = req.clone({url: `${this.baseUrl}${req.url}`});
    }
    return next.handle(apiReq);
  }


}
