import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'order-invoice-information',
  templateUrl: './order-invoice-information.component.html',
  styleUrls: ['./order-invoice-information.component.scss']
})
export class OrderInvoiceInformationComponent implements OnInit {
  @Input() data ;
  @Input() invoiceId ;
  @Input() exchangeRate ;
  @Input() countryCode ;
  @Input() orderStatus ;
  alignText  = 'ltr';
  constructor() { }

  ngOnInit(): void {
    if(this.countryCode == 'IQ'){
      this.alignText = 'rtl';
    }
  }

}
