<div id="user_{{member['node_id'] + '_' + member['business_center']}}" class="tree_img_tree" style="width: 100% !important; overflow: hidden !important;">
  <div class="Demo_head_bg">
    <img
      src="{{ imgUrl + member.user.avatar }}"
      onerror="this.src='assets/images/users/avatar.png'"/>
    <p>{{member['node_id'] + '-BC-' + member['business_center']}}</p>
  </div>
  <div class="body_text_tree">
    <div class="binary_bg">
      <p class="text-center font-size-13">{{member.user.email}}</p>
    </div>
    <ul class="list-group no-radius">
      <li class="list-group-item">
        <div class="pull-right">:&nbsp;&nbsp;{{member.user.first_name }}
        </div>
        <div class="pull-left">Name</div>
      </li>
      <li class="list-group-item">
        <div class="pull-right">:&nbsp;&nbsp;&nbsp;{{member.node_id }}</div>
        <div class="pull-left">Node Id</div>
      </li>
      <li class="list-group-item">
        <div class="pull-right">:&nbsp;&nbsp;{{member.alignment_tree }}
        </div>
        <div class="pull-left">Alignment</div>
      </li>
      <li class="list-group-item">
        <div class="pull-right">:&nbsp;&nbsp;{{member.depth }}</div>
        <div class="pull-left">Depth</div>
      </li>
      <li class="list-group-item">
        <div class="pull-right">:&nbsp;&nbsp;{{member.left_count }}</div>
        <div class="pull-left">Left Carry</div>
      </li>
      <li class="list-group-item">
        <div class="pull-right">:&nbsp;&nbsp;{{member.right_count }}</div>
        <div class="pull-left">Right Carry</div>
      </li>
    </ul>
  </div>
</div>
