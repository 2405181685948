import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginateModels, ResponseModal} from "../models/paginate.models";
import {Language} from "../models/language.models";

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'es', 'de', 'it', 'ru'];

  private baseUrl = `/setting/languages`;

  constructor(public translate: TranslateService, private cookieService: CookieService, private http: HttpClient) {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang');
    }
    else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|es|de|it|ru/) ? browserLang : 'en');
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    this.cookieService.set('lang', lang);
  }

  getAll(): Observable<ResponseModal<Language[]>> {
    return this.http.get<ResponseModal<Language[]>>(this.baseUrl);
  }

  getPaginateByFilter(params: string = ''): Observable<PaginateModels<Language[]>> {
    let url = this.baseUrl + (params.length > 0 ? '?' +  params : "")
    return this.http.get<PaginateModels<Language[]>>(url);
  }

  getById(id: number): Observable<ResponseModal<Language>> {
    let url =  `${this.baseUrl}/${id}` ;
    return this.http.get<ResponseModal<Language>>(url);
  }

  removeById(id: any ): Observable<ResponseModal<any>>{
    return this.http.delete<ResponseModal<any>>(`${this.baseUrl}/${id}`);
  }

  store(model: Language): Observable<ResponseModal<Language>> {
    if(model.lang_id > 0 ) {
      return this.http.put<ResponseModal<Language>>(`${this.baseUrl}/${model.lang_id}`, model);
    }else {
      return this.http.post<ResponseModal<Language>>(this.baseUrl, model);
    }
  }
}
