<div [ngSwitch]="countryCode" class="py-2 "
     style="padding-left: 10px; padding-right: 10px ; font-size : 28px ;direction: {{alignText}}">
  <h3 *ngSwitchCase="'IQ'" class=" font-weight-bold">تفاصيل المواد</h3>
  <h3 *ngSwitchCase="'TR'" class=" font-weight-bold">Items Details </h3>
  <h3 *ngSwitchDefault class=" font-weight-bold">Items Details </h3>
</div>
<div class="table-responsive" style="padding-left: 10px; padding-right: 10px; direction: {{alignText}}">
  <table class="table">
    <thead [ngSwitch]="countryCode">
    <tr *ngSwitchCase="'IQ'">
      <th style="width: 50px;">الرقم</th>
      <th>التفاصیل</th>
      <th class="text-right">سعر القطعة</th>
      <th class="text-right">الکمیة</th>
      <th class="text-right">السعر الجمالي</th>
    </tr>
    <tr *ngSwitchCase="'TR'">
      <th style="width: 50px;">No</th>
      <th>Item</th>
      <th class="text-right">Unit Price</th>
      <th class="text-right">QTY</th>
      <th class="text-right">Total Price</th>
    </tr>
    <tr *ngSwitchDefault>
      <th style="width: 50px;">No</th>
      <th>Item</th>
      <th class="text-right">Unit Price</th>
      <th class="text-right">QTY</th>
      <th class="text-right">Total Price</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of products ;let i = index ">
      <td>{{i + 1}}</td>
      <td>{{row.product_name}}</td>
      <td class="text-right">{{row.product_price | currency}}
        <span
          *ngIf="exchangeRate != 1"><br>{{row.product_price * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>
      </td>
      <td class="text-right">{{row.product_quantity}}</td>
      <td class="text-right">{{row.product_price * row.product_quantity | currency}}
        <span
          *ngIf="exchangeRate != 1"><br>{{(row.product_price * row.product_quantity) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

      </td>
    </tr>


    </tbody>

    <ng-container [ngSwitch]="countryCode">
      <tfoot *ngSwitchCase="'IQ'">
      <tr>
        <td colspan="4" class="text-right" style="text-align: end; font-size: medium">التكلفة الابتدائية</td>
        <td class="text-right" style="font-size: medium">{{(getTotalProducts(products)) | currency  }}

          <span
            *ngIf="exchangeRate != 1"><br> {{(getTotalProducts(products)) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.shopping_type =='shopping'">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong>عمولة التجزئة</strong></td>
        <td class="border-0 text-right text-danger">-{{data.total_discounts | currency}}
          <span
            *ngIf="exchangeRate != 1"><br>{{(data.total_discounts) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.payment_id ==1">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong>كلفة بالنقاط</strong></td>
        <td class="border-0 text-right ">{{data.total_points }}
        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.payment_id ==1 && data.shopping_type =='shopping'">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong>عمولة التجزئة بالنقاط</strong></td>
        <td class="border-0 text-right text-danger">-{{data.total_points_discounts }}
        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.total_shipping > 0">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong> الشحن</strong></td>
        <td class="border-0 text-right">{{data.total_shipping | currency}}
          <span
            *ngIf="exchangeRate != 1"><br> {{(data.total_shipping) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.payment_fee > 0">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong> رسوم بوابة الدفع</strong></td>
        <td class="border-0 text-right">{{data.payment_fee | currency}}
          <span
            *ngIf="exchangeRate != 1"><br>{{(data.payment_fee) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.total_extra_fees > 0">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong>رسوم الخدمات المحلية</strong></td>
        <td class="border-0 text-right">{{data.total_extra_fees | currency}}
          <span
            *ngIf="exchangeRate != 1"><br>{{(data.total_extra_fees) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds"  >
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong> الإجمالی</strong> </td>
        <td class="border-0 text-right">
          <h4 *ngIf="data.payment_id != 1" class="m-0">{{data.total_paid | currency}}
            <span *ngIf="exchangeRate != 1 "   style="font-size: medium"> <br>{{(data.total_paid) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>
          </h4>
          <h4 *ngIf="data.payment_id == 1" class="m-0">{{(data.total_extra_fees +data.total_shipping) | currency}}
            <span *ngIf="exchangeRate != 1 "   style="font-size: medium"> <br>{{(data.total_extra_fees +data.total_shipping) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>
          </h4>
        </td>
      </tr>
      </tfoot>
      <tfoot *ngSwitchCase="'TR'">
      <tr>
        <td colspan="4" class="text-right" style="text-align: end; font-size: medium">Sub Total </td>
        <td class="text-right" style="font-size: medium">{{(getTotalProducts(products)) | currency  }}

          <span
            *ngIf="exchangeRate != 1"><br> {{(getTotalProducts(products)) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.payemnt_id ==1">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong>Total Points </strong></td>
        <td class="border-0 text-right ">{{data.total_points}}
        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.shopping_type =='shopping' && data.payemnt_id ==1">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong>Retail Discount </strong></td>
        <td class="border-0 text-right text-danger">-{{data.total_points_discounts}}
                  </td>
      </tr>
      <tr *ngIf="detailsFunds && data.shopping_type =='shopping'">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong>Retail Discount </strong></td>
        <td class="border-0 text-right text-danger">-{{data.total_discounts | currency}}
          <span
            *ngIf="exchangeRate != 1"><br>{{(data.total_discounts) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.total_shipping > 0">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong> Shipping Fee</strong></td>
        <td class="border-0 text-right">{{data.total_shipping | currency}}
          <span
            *ngIf="exchangeRate != 1"><br> {{(data.total_shipping) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.payment_fee > 0">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong> Payment Fee  </strong></td>
        <td class="border-0 text-right">{{data.payment_fee | currency}}
          <span
            *ngIf="exchangeRate != 1"><br>{{(data.payment_fee) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.total_extra_fees > 0">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong>Local Service fee  </strong></td>
        <td class="border-0 text-right">{{data.total_extra_fees | currency}}
          <span
            *ngIf="exchangeRate != 1"><br>{{(data.total_extra_fees) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds"  >
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong> Total</strong></td>
        <td class="border-0 text-right">
          <h4 *ngIf="data.payment_id != 1" class="m-0">{{data.total_paid | currency}}
            <span *ngIf="exchangeRate != 1 "   style="font-size: medium"> <br>{{(data.total_paid) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>
          </h4>
          <h4 *ngIf="data.payment_id == 1" class="m-0">{{(data.total_extra_fees +data.total_shipping) | currency}}
            <span *ngIf="exchangeRate != 1 "   style="font-size: medium"> <br>{{(data.total_extra_fees +data.total_shipping) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>
          </h4>
        </td>
      </tr>
      </tfoot>
      <tfoot *ngSwitchDefault>
      <tr>
        <td colspan="4" class="text-right" style="text-align: end; font-size: medium">Sub Total </td>
        <td class="text-right" style="font-size: medium">{{(getTotalProducts(products)) | currency  }}

          <span
            *ngIf="exchangeRate != 1"><br> {{(getTotalProducts(products)) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.shopping_type =='shopping' && data.total_discounts > 0">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong>Retail Discount </strong></td>
        <td class="border-0 text-right text-danger">-{{data.total_discounts | currency}}
          <span
            *ngIf="exchangeRate != 1"><br>{{(data.total_discounts) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.payment_id ==1">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong>Total Point  </strong></td>
        <td class="border-0 text-right text-danger">-{{data.total_points }}
        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.payment_id ==1">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong>Retail Point sDiscount </strong></td>
        <td class="border-0 text-right text-danger">-{{data.total_points_discounts }}
                 </td>
      </tr>
      <tr *ngIf="detailsFunds && data.total_shipping > 0">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong> Shipping Fee</strong></td>
        <td class="border-0 text-right">{{data.total_shipping | currency}}
          <span
            *ngIf="exchangeRate != 1"><br> {{(data.total_shipping) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.payment_fee > 0">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong> Payment Fee  </strong></td>
        <td class="border-0 text-right">{{data.payment_fee | currency}}
          <span
            *ngIf="exchangeRate != 1"><br>{{(data.payment_fee) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds && data.total_extra_fees > 0">
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong>Local Service fee  </strong></td>
        <td class="border-0 text-right">{{data.total_extra_fees | currency}}
          <span
            *ngIf="exchangeRate != 1"><br>{{(data.total_extra_fees) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>

        </td>
      </tr>
      <tr *ngIf="detailsFunds"  >
        <td colspan="4" class="border-0 text-right" style="text-align: end;">
          <strong> Total</strong></td>
        <td class="border-0 text-right">
          <h4 *ngIf="data.payment_id != 1" class="m-0">{{data.total_paid | currency}}
            <span *ngIf="exchangeRate != 1 "   style="font-size: medium"> <br>{{(data.total_paid) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>
          </h4>
          <h4 *ngIf="data.payment_id == 1" class="m-0">{{(data.total_extra_fees +data.total_shipping) | currency}}
            <span *ngIf="exchangeRate != 1 "   style="font-size: medium"> <br>{{(data.total_extra_fees +data.total_shipping) * exchangeRate | currency: ' ':'symbol':'4.0-0'}} {{exchangeRate.symbol}}</span>
          </h4>
        </td>
      </tr>
      </tfoot>


    </ng-container>
  </table>
</div>
