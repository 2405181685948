import { Directive, OnInit, ElementRef, Input } from '@angular/core';
import {PermissionService} from './permission.service/permission.service';

// https://github.com/JavadRasouli/ng2-permission
@Directive({
  selector: '[exceptPermission]'
})
export class ExceptPermissionDirective implements OnInit {
  @Input('exceptPermission') exceptPermission: Array<string>;


  constructor(private _elem: ElementRef, private _permissionService: PermissionService) { }

  ngOnInit() {
    this._permissionService.permissionStoreChangeEmitter
      .subscribe(() => {
        this.applyPermission();
      });

    this.applyPermission();
  }

  applyPermission() {
    const hasDefined = this._permissionService.hasOneDefined(this.exceptPermission);

    if (hasDefined) {
        this._elem.nativeElement.style.display = 'none';
    } else {
        this._elem.nativeElement.style.display = 'inherit';
    }
  }
}

