


  <div *ngIf="showDownIcon" >
    <img
      src="/assets/images/tree/down.png"
      class='tree_down_icon'
      [id]="nodeId"
      [attr.node-id]="nodeId"
      onclick="expandGenealogyTree(event)"/>
  </div>


<img class="tree_icon with_tooltip {{isRoot ? 'root_node' : '' }}"
      src="{{ avatarImg}}"
      onerror="this.src='assets/images/users/avatar.png'"
     ondblclick="genealogyTree.getGenealogyTree(node['node_id'],event)"
      [attr.data-tooltip-content]="'#user_' + nodeId"/>
<!--$node['business_center'] == '001'-->
<p class='demo_name_style' style="padding: 5px">{{title}}</p>


<div *ngIf="showUpIcon">
  <img
    src="/assets/images/tree/up.png"
    class='tree_up_icon'
    id="tree_up_icon"
    onclick="getGenealogyTree(node['parent_id']);"/>
</div>

