import {  Directive, OnInit, ElementRef, Input,  Renderer2} from '@angular/core';
import {PermissionService} from './permission.service/permission.service';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
  @Input('hasPermission') permissions: Array<string>;
  subscription: any;

  constructor(private _elem: ElementRef,
              // private templateRef: TemplateRef<any>,
              private renderer: Renderer2,
              private _permissionService: PermissionService,
              // private viewContainer: ViewContainerRef
  ) { }

  ngOnInit() {
    this.subscription = this._permissionService.permissionStoreChangeEmitter
      .subscribe(() => {
        this.applyPermission();
      });

     this.applyPermission();
  }

  applyPermission() {
    if (this.permissions !== undefined) {
       const  hasDefined = this._permissionService.hasOneDefined(this.permissions);
      const parent = this._elem.nativeElement.parentNode;
        if (!hasDefined) {
           // this._elem.nativeElement.style.display = 'none';
          //   this.renderer.setStyle(this._elem.nativeElement, 'display', 'none');
          this._elem.nativeElement.style.display = 'none';
          // this.renderer.removeChild(parent, this._elem.nativeElement);
             // console.log(this.viewContainer);
        } else {
         // this.renderer.setElementStyle(this._elem.nativeElement, 'display', '');
            // this.viewContainer.createEmbeddedView(this._elem.nativeElement);
            // this._elem.nativeElement.style.display = 'inherit';
          // this.viewContainer.createEmbeddedView(this.templateRef);
        }


    }
  }



}
