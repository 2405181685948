<div *ngIf="data" class="card-body" style=" -webkit-print-color-adjust: exact !important;">

  <order-invoice-header [countryCode]="data?.member?.country_code"></order-invoice-header>
  <hr>

  <order-invoice-information [data]="data" [exchangeRate]="exchangeRate" [invoiceId]="orderStatus  == 'Re-Order' ? data.re_order_id : data?.order_id" [countryCode]="data?.member?.country_code" [orderStatus]="orderStatus"></order-invoice-information>
  <hr>


  <order-invoice-table [detailsFunds]="detailsFunds" [data]="data" [products]="products" [orderStatus]="orderStatus" [exchangeRate]="exchangeRate" [countryCode]="data?.member?.country_code"></order-invoice-table>

  <order-invoice-footer [countryCode]="data?.member?.country_code"></order-invoice-footer>

  <div class="row" style=" border-bottom: 8px #283592 solid; padding: 20px; margin-bottom: 30px"></div>
  <div class="d-print-none">
    <div class="d-flex flex-wrap gap-2 float-right">
      <a href="javascript:window.print()" class="btn btn-success"><i class="fa fa-print"></i></a>
      <a (click)="handleBackUrl()" href="javascript: void(0);"
         class="btn btn-primary w-md ml-1"> {{ 'PAGES.NETWORK.MEMBERS.MEMBER.BTN_BACK' | translate}}</a>
    </div>
  </div>
</div>
