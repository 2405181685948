import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-children-nodes-tree',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './children-nodes-tree.component.html',
  styleUrls: ['./children-nodes-tree.component.scss']
})
export class ChildrenNodesTreeComponent implements OnInit {
  @Input() node: any;
  @Input() rootId: number;
  constructor() { }

  ngOnInit(): void {
  }

}
